import React, { useContext, useEffect, useState } from "react";
import { Link, Route, Switch, useLocation, useParams } from "react-router-dom";
import { HomeIcon, UserGroupIcon, PlusCircleIcon } from "@heroicons/react/outline";
import { useDispatch, useSelector } from "react-redux";
import { readMyGroupsAsync } from "../../../features/group/groupSlice";
import { getImageSrc } from "../../../utils";
import GroupForm from "../../forms/GroupForm";
import FormModal from "../../forms/FormModal";
import CollectionContentList from "../collections/ContentList";
import GroupContentList from "../groups/group-page-tabs/GroupContentList";
import { CollectionContext } from "../../general/currentCollectionContext";
import { useIsLeftNavbarVisible } from "../../general/hooks/useIsLeftNavbarVisible";
import ContentLink from "../../general/ContentLink";

// eslint-disable-next-line no-unused-vars
function CreateGroupButton({ setModalShowing }) {
  return (
    <li
      onClick={() => setModalShowing(true)}
      className="pr-2 my-1.5 cursor-pointer hover:opacity-100 opacity-40 text-ellipsis whitespace-nowrap overflow-hidden"
    >
      <PlusCircleIcon className="w-7 h-7 -ml-0.5 mr-4 inline-block" />
      <span className="pt-2">Create group</span>
    </li>
  );
}

function MyGroupsList({ myGroups, currentUser, setModalShowing }) {
  if (!myGroups) return null;

  return (
    <>
      <p className="my-2 mt-6 font-bold">My groups</p>
      <ul>
        {myGroups.map(group => (
          <GroupLinkItem group={group} key={group.id} />
        ))}
        {/* {(currentUser?.role === "admin" || currentUser?.role === "creator") && (
            <CreateGroupButton setModalShowing={setModalShowing} />
          )} */}
      </ul>
    </>
  );
}

function GroupLinkItem({ group }) {
  const url = group.externalGroupUrl || `/groups/${group.slug}`;
  const isExternal = !!group.externalGroupUrl;

  return (
    <ContentLink to={url} isExternal={isExternal}>
      <li className="pr-2 my-1.5 hover:opacity-100 opacity-70 text-ellipsis whitespace-nowrap overflow-hidden">
        <div
          style={{
            backgroundImage: `url(${getImageSrc(group.coverImg)}?tr=w-48,h-48,q-80)`
          }}
          className="w-6 h-6 mr-4 inline-block bg-cover rounded-full align-middle bg-center"
        />
        <span>{group.name}</span>
      </li>
    </ContentLink>
  );
}

const LeftNavbarWithRouter = () => {
  const location = useLocation();

  return (
    <Switch location={location}>
      <Route exact path="/" children={<LeftNavbar />} />
      <Route exact path="/dashboard" children={null} />
      <Route exact path="/dashboard/:page" children={null} />
      <Route exact path="/embed/:page" children={null} />
      <Route exact path="/reset-password" component={null} />
      <Route exact path="/groups/:slug" component={LeftNavbar} />
      <Route exact path="/groups/:slug/:collection_slug/:content_id" component={LeftNavbar} />
      <Route exact path="/groups" component={LeftNavbar} />
      <Route path="/creators" children={<LeftNavbar />} />
      <Route exact path="/profile/:userId" component={LeftNavbar} />
      <Route path="/search" children={<LeftNavbar />} />
      <Route path="/tags/:tag" children={<LeftNavbar />} />
      <Route exact path="/topic/:topic_slug" children={<LeftNavbar />} />
      <Route exact path="/viewcontent/:content_id" component={LeftNavbar} />
      <Route exact path="/:collection_slug" children={<LeftNavbar />} />
      <Route exact path="/:collection_slug/:content_id" component={LeftNavbar} />
    </Switch>
  );
};

const LeftNavbar = () => {
  const dispatch = useDispatch();
  const { collection } = useContext(CollectionContext);
  const { slug: groupSlug, collection_slug } = useParams();
  const { currentUser } = useSelector(state => state.auth);
  const { platformData } = useSelector(state => state.frontend);
  const { myGroups } = useSelector(state => state.group);
  const [isModalShowing, setModalShowing] = useState(false);
  const { isLeftNavbarVisible, setIsLeftNavbarVisible } = useIsLeftNavbarVisible();

  const isUserLoggedIn = currentUser?.id;

  useEffect(() => {
    if (currentUser) dispatch(readMyGroupsAsync());
  }, [currentUser, dispatch]);

  useEffect(() => {
    setIsLeftNavbarVisible(isUserLoggedIn || collection_slug);
  }, [collection_slug, isUserLoggedIn, setIsLeftNavbarVisible]);

  if (!isLeftNavbarVisible) return null;
  return (
    <div className="w-80 hidden lg:flex lg:flex-shrink-0 fixed inset-0 shadow-xl overflow-y-scroll platform-dark-color bg-platform-light-color">
      <nav className="bg-gray-900 bg-opacity-5 p-3 pt-16 h-full w-full">
        {isUserLoggedIn && (
          <div className="text-lg mt-4">
            <i>Welcome back,</i> <b className="block">{currentUser.name}</b>
          </div>
        )}

        {groupSlug ? (
          <GroupContentList currentUser={currentUser} groupSlug={groupSlug} />
        ) : (
          <>
            {isUserLoggedIn && (
              <Link
                to="/"
                className="group flex items-center px-2 py-2 text-base font-medium rounded-md hover:opacity-100 opacity-80"
              >
                <HomeIcon className="h-6 w-6 mr-2" />
                Home
              </Link>
            )}
            {isUserLoggedIn && myGroups?.length > 0 && (
              <Link
                to="/groups"
                className="group flex items-center px-2 py-2 text-base font-medium rounded-md hover:opacity-100 opacity-80"
              >
                <UserGroupIcon className="h-6 w-6 mr-2" />
                Groups
              </Link>
            )}
            {isUserLoggedIn && myGroups?.length > 0 && collection != null && (
              <MyGroupsList
                currentUser={currentUser}
                setModalShowing={setModalShowing}
                myGroups={myGroups}
              />
            )}
            {collection && collection_slug && (
              <>
                <Link
                  to={`/${collection.slug}`}
                  className="font-bold text-xl block mt-10 leading-none"
                  style={{
                    color: `${platformData.primaryColor}`
                  }}
                >
                  {collection.name}
                </Link>
                <CollectionContentList collection={collection} />
              </>
            )}
          </>
        )}

        <FormModal
          modalShowing={isModalShowing}
          closeModal={() => setModalShowing(false)}
          Form={GroupForm}
          title="Create group"
        />
      </nav>
    </div>
  );
};

export default LeftNavbarWithRouter;
